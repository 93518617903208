import React from "react"
import styled from "@emotion/styled"
import "flickity/css/flickity.css"
import { injectIntl, Link } from "gatsby-plugin-intl"
import ReviewSlider from "../ReviewSlider"
import TrustPilotWidgetSlider from "../TrustPilotWidgetSlider"

const ReviewHomeSection = ({ intl, data, page }) => {
  return (
    data.desktop_image &&
    data.mobile_image && (
      <ReviewHomeSectionWrapper
        desktp_image={data.desktop_image}
        mobile_image={data.mobile_image}
      >
        {data.desktop_image && (
          <DesktopImageWrapper>
            <ImageBox>
              <Image
                className="lazyload"
                data-src={data.desktop_image}
                alt={intl.formatMessage({
                  id: "lyne_up_femme_heureuse_tshirt_dos",
                })}
              />
            </ImageBox>
          </DesktopImageWrapper>
        )}
        <InnovationContentWrapper>
          <ContentBox>
            {data.title && (
              <SectionTitle dangerouslySetInnerHTML={{ __html: data.title }} />
            )}

            {(intl.locale === "fr" || intl.locale === "en") && (
              <TrustPilotWidgetSlider intl={intl} tags="HP" page="home" />
            )}

            {/* {(intl.locale === "fr" || intl.locale === "en") && <ReviewSlider />} */}
            {page != "lyneup" && data.button_url && data.button_text && (
              <ButtonWrapper>
                <ProductButton to={data.button_url}>
                  {data.button_text}
                </ProductButton>
              </ButtonWrapper>
            )}
          </ContentBox>
        </InnovationContentWrapper>
      </ReviewHomeSectionWrapper>
    )
  )
}

const ReviewHomeSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px;
  background: #fff;
  position: relative;
  min-height: 440px;
  justify-content: center;

  @media (max-width: 850px) {
    background-image: ${({ desktp_image }) =>
      desktp_image ? `url(${desktp_image})` : ""};
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  @media (max-width: 550px) {
    background-image: ${({ mobile_image }) =>
      mobile_image ? `url(${mobile_image})` : ""};
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
  }
`

const InnovationContentWrapper = styled.div`
  width: 100%;
  padding: 20px;
  max-width: 1080px;
  margin: 0px auto;
  z-index: 1;

  @media (max-width: 550px) {
    padding: 40px 20px;
  }
`

const DesktopImageWrapper = styled.div`
  width: 100%;
  max-width: 1600px;
  margin: 0px;
  height: 100%;
  position: absolute;
  padding-right: 20px;

  @media (max-width: 850px) {
    display: none;
  }
`

const ImageBox = styled.div`
  border-radius: 0px 0px 100px 0px;
  display: flex;
  object-fit: cover;
  height: 100%;
  overflow: hidden;
`

const Image = styled.img`
  width: 100%;
  object-fit: cover;
  margin: 0px;
  height: 100%;
`

const ContentBox = styled.div`
  width: 100%;
  max-width: 690px;

  @media (max-width: 850px) {
    max-width: 500px;
  }
`

const SectionTitle = styled.div`
  font-size: 32px;
  line-height: 1.2;
  font-family: "BebasNeueBold";
  font-weight: bold;
  color: #262626;

  span {
    color: #fff;
  }

  @media (max-width: 850px) {
    font-size: 24px;
  }
`

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  position: relative;
  z-index: 1;
`

const ProductButton = styled(Link)`
  background: #262626;
  border-radius: 5px;
  padding: 12px 20px;
  color: #fff;
  font-family: "Museo";
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  position: relative;
  border-radius: 25px 0;

  :hover {
    color: #262626;
    background: ${props => props.theme.gradient.buttonGradient1};
    :after {
      background: ${props => props.theme.colors.button.black};
    }
  }

  :after {
    content: "";
    position: absolute;
    top: 5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    background: ${props => props.theme.gradient.buttonGradient1};
    border-radius: 25px 0;
    z-index: -1;
  }

  @media (max-width: 550px) {
    width: 100%;
    text-align: center;
  }
`

export default injectIntl(ReviewHomeSection)
