import React from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
  color: #262626;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-width: 1900px;
  padding: 0px 20px;
  margin: 0px auto;
  position: relative;

  @media (max-width: 600px) {
    flex-direction: column;
    padding: 15px 20px;
  }
`

const ProductBoxWrapper = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

const ProductBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin: 0px 10px;

  :first-of-type {
    margin-left: 0px;
  }

  :last-of-type {
    margin-right: 0px;
  }

  @media (max-width: 600px) {
    margin: 0px;
  }
`

const TopSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const ImageBox = styled.div`
  width: 100%;
  display: flex;
  margin: 0px 0px 20px;

  @media (max-width: 600px) {
    width: calc(100% + 40px);
    margin: 0px 0px 20px -20px;
  }
`

const Image = styled.img`
  width: 100%;
  margin: 0px;
  object-fit: contain;
`

const ProductTitle = styled.h2`
  font-size: 35px;
  line-height: 1;
  font-family: "Museo";
  font-weight: 900;
  margin: 0px 0px 15px 0px;

  @media (max-width: 1800px) {
    font-size: 30px;
  }

  @media (max-width: 992px) {
    font-size: 24px;
    word-break: break-word;
  }
`

const ProductSubTitle = styled.p`
  font-size: 14px;
  line-height: 1.2;
  font-family: "Gotham Book";
  font-weight: 500;
  margin: 0px;
`

const FlexWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin: 15px 0px 20px;
`

const StyledLink = styled(Link)`
  font-family: "Museo";
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  color: ${props => props.theme.colors.button.black};
  border: ${props => ` 1px solid ${props.theme.colors.button.black}`};
  font-size: 18px;
  padding: 15px 20px;
  line-height: 1;
  position: relative;

  :hover {
    color: ${props => props.theme.colors.white.base};
    background: ${props => props.theme.colors.button.black};
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`

const BottomHomeSection = ({ intl, data }) => {
  return (
    data && (
      <Wrapper>
        <Container>
          <ProductBoxWrapper>
            {data.list.map((item, index) => {
              return (
                item && (
                  <ProductBox key={index.toString()}>
                    <TopSection>
                      {item.image && (
                        <ImageBox>
                          <Image
                            className="lazyload"
                            data-src={item.image}
                            alt={item.alt ? item.alt : ""}
                            order={index}
                          />
                        </ImageBox>
                      )}
                      {item.title && (
                        <ProductTitle
                          dangerouslySetInnerHTML={{
                            __html: item.title,
                          }}
                        />
                      )}
                      {item.text && (
                        <ProductSubTitle
                          dangerouslySetInnerHTML={{
                            __html: item.text,
                          }}
                        />
                      )}
                    </TopSection>
                    {item.button_text && item.button_url && (
                      <FlexWrapper>
                        <StyledLink to={item.button_url}>
                          {item.button_text}
                        </StyledLink>
                      </FlexWrapper>
                    )}
                  </ProductBox>
                )
              )
            })}
          </ProductBoxWrapper>
        </Container>
      </Wrapper>
    )
  )
}

export default injectIntl(BottomHomeSection)
