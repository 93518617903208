import React from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"
import ClockIcon from "../../../../static/Clock.svg"

const HomeFlashSectionWrapper = styled.div`
  width: 100%;
  position: relative;
  background: #322ee5;
  margin-bottom: 30px;

  @media (max-width: 600px) {
    margin-bottom: 20px;
  }
`

const VentesContainer = styled.div`
  width: 100%;
  max-width: 1300px;
  padding: 10px 10px;
  margin: 0px auto;
  display: flex;
  justify-content: center;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

const TextBox = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "Gotham Book";
  font-weight: bold;
  font-size: 21px;
  line-height: 1.2;
  color: ${props => props.theme.saleColor.textColor};
  color: #fff;
  text-align: left;
`

const LeftText = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  font-family: "Gotham Book";
  font-weight: bold;
  font-size: 16px;
  line-height: 1;
  color: ${props => props.theme.saleColor.textColor};
  color: #fff;
  min-width: 340px;

  > span:nth-of-type(1) {
    font-size: 24px;
    font-family: "Gotham";
    font-weight: bold;
    margin: 0px 7px;

    @media (max-width: 600px) {
      margin: 0px 5px;
      font-size: 35px;
    }
  }

  span > span {
    @media (max-width: 600px) {
      display: block;
    }
  }

  @media (max-width: 600px) {
    font-size: 14px;
    min-width: auto;
  }

  @media (max-width: 360px) {
    font-size: 13px;
  }
`

const RightText = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: "Gotham Book";
  font-weight: bold;
  font-size: 16px;
  line-height: 1;
  color: ${props => props.theme.saleColor.textColor};
  color: #fff;

  > span:nth-of-type(1) {
    margin: 0px 7px;
    font-family: "Gotham";
    font-weight: bold;
    font-size: 24px;
    // padding: 7px 8px 6px;
    // border-radius: 10px 0px;
    // background: ${props => props.theme.saleColor.textColor};
    // color: ${props => props.theme.saleColor.bgColor};
    color: #FEDB81;

    @media (max-width: 600px) {
      margin: 0px 0px 2px 0px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 21px;
      // padding: 5px 5px 3px;
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
    margin-left: 5px;
    font-size: 14px;
  }

  @media (max-width: 360px) {
    font-size: 13px;
  }
`

const ClockImage = styled.img`
  width: 100%;
  max-width: 30px;
  min-width: 30px;
  margin: 0px;
`

const HomeFlashSection = ({ intl }) => {
  return (
    <HomeFlashSectionWrapper>
      <VentesContainer>
        <TextBox>
          <LeftText>
            <ClockImage className="lazyload" data-src={ClockIcon} />
            <span>-10%</span>
            <span>
              supplémentaires <span>avec le code</span>
            </span>
          </LeftText>
          <RightText>
            <span>FLASH</span>
            <span>jusqu'au 04/06</span>
          </RightText>
        </TextBox>
      </VentesContainer>
    </HomeFlashSectionWrapper>
  )
}

export default injectIntl(HomeFlashSection)
