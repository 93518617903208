import React from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"

const VentesOldLyneSectionWrapper = styled.div`
  width: 100%;
  position: relative;
  background: #e7f0f3;
  margin-bottom: 30px;

  @media (max-width: 600px) {
    margin-bottom: 20px;
  }
`

const VentesContainer = styled.div`
  width: 100%;
  max-width: 1300px;
  padding: 15px 20px;
  margin: 0px auto;
  display: flex;
  justify-content: center;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

const DesktopBox = styled.div`
  display: flex;

  @media (max-width: 600px) {
    display: none;
  }
`

const MobileBox = styled.div`
  display: none;
  width: 100%;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    display: flex;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: #262626;
  margin: 0px 30px;

  @media (max-width: 992px) {
    margin: 0px 20px;
  }

  @media (max-width: 600px) {
    margin: 0px;
  }
`

const MedicalImage = styled.img`
  max-width: 130px;
  margin: 0px;

  @media (max-width: 992px) {
    max-width: 80px;
  }
`

const Title = styled.div`
  font-size: 35px;
  line-height: 1.2;
  font-family: "Museo";
  font-weight: 900;
  width: 100%;

  span {
    @media (max-width: 600px) {
      display: block;
    }
  }

  @media (max-width: 1600px) {
    font-size: 30px;
  }

  @media (max-width: 992px) {
    font-size: 24px;
  }

  @media (max-width: 600px) {
    text-align: center;
  }
`

const BottomWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0px 0px;

  @media (max-width: 992px) {
    margin: 10px 0px 0px;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
  }
`

const Text = styled.div`
  font-size: 18px;
  line-height: 1.2;
  font-family: "Gotham Book";
  font-weight: 500;
  text-align: center;

  strong {
    font-weight: bold;
  }

  span {
    font-family: "Gotham";
    font-weight: bold;
    color: ${props => props.theme.saleColor.bgColor};
    font-size: 21px;

    @media (max-width: 1600px) {
      font-size: 21px;
    }
  }

  @media (max-width: 1600px) {
    font-size: 14px;
  }

  @media (max-width: 992px) {
    text-align: left;
  }

  @media (max-width: 600px) {
    font-weight: bold;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  margin-left: 50px;

  @media (max-width: 992px) {
    margin-left: 20px;
  }

  @media (max-width: 600px) {
    justify-content: center;
    margin: 10px 0px 0px;
  }
`

const Button = styled(Link)`
  color: #262626;
  font-family: "Museo";
  font-weight: bold;
  font-size: 18px;
  line-height: 1;
  text-align: center;
  border-radius: 20px 0px;
  border: 1px solid #262626;
  padding: 15px 20px;
  position: relative;
  margin: 0px 10px;

  :hover {
    background: #262626;
    color: #fff;
  }
`

const BannerImage = styled.img`
  width: 100%;
  max-width: 220px;
  margin: 0px;
  object-fit: contain;

  @media (max-width: 992px) {
    max-width: 180px;
  }
`

const VentesOldLyneSection = ({ intl, data }) => {
  return (
    data && (
      <VentesOldLyneSectionWrapper>
        <VentesContainer>
          {data.logo_image && (
            <DesktopBox>
              <MedicalImage
                className="lazyload"
                data-src={data.logo_image}
                alt={data.logo_alt ? data.logo_alt : ""}
              />
            </DesktopBox>
          )}
          <TextWrapper>
            {data.title && (
              <Title
                dangerouslySetInnerHTML={{
                  __html: data.title,
                }}
              />
            )}
            <MobileBox>
              {data.logo_image && (
                <MedicalImage
                  className="lazyload"
                  data-src={data.logo_image}
                  alt={data.logo_alt ? data.logo_alt : ""}
                />
              )}
              {data.image && (
                <BannerImage
                  className="lazyload"
                  data-src={data.image}
                  alt={data.alt ? data.alt : ""}
                />
              )}
            </MobileBox>
            <BottomWrapper>
              {data.text && (
                <Text
                  dangerouslySetInnerHTML={{
                    __html: data.text,
                  }}
                />
              )}
              {data.button_list && (
                <ButtonWrapper>
                  {data.button_list.map((item, index) => {
                    return (
                      item && (
                        <Button to={item.url} key={index.toString()}>
                          {item.text}
                        </Button>
                      )
                    )
                  })}
                </ButtonWrapper>
              )}
            </BottomWrapper>
          </TextWrapper>
          {data.image && (
            <DesktopBox>
              <BannerImage
                className="lazyload"
                data-src={data.image}
                alt={data.alt ? data.alt : ""}
              />
            </DesktopBox>
          )}
        </VentesContainer>
      </VentesOldLyneSectionWrapper>
    )
  )
}

export default injectIntl(VentesOldLyneSection)
