import React from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"
import HomeCrossSlider from "../HomeCrossSlider"

const HomeCrossSection = ({ intl, data, price, page }) => {
  return (
    <CrossProductSectionWrapper>
      <Wrapper>
        {data.section_title && (
          <RowBox>
            <LyneHomeMaisonSectionTitle
              dangerouslySetInnerHTML={{
                __html: data.section_title,
              }}
            />
          </RowBox>
        )}
        <RowBox>
          {data.product_list && (
            <ColumnBox>
              <HomeCrossSlider data={data} price={price} />
            </ColumnBox>
          )}
        </RowBox>
        <MattressMessage>
          *D’après une étude quantitative menée en mars 2023 sur 106
          utilisateurs du matelas PERCKO
        </MattressMessage>
      </Wrapper>
    </CrossProductSectionWrapper>
  )
}

const CrossProductSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  padding: 40px 20px;
  margin: 0px auto;
  align-items: center;
`

const LyneHomeMaisonSectionTitle = styled.h2`
  font-size: 40px;
  line-height: 1;
  font-family: "BebasNeueBold";
  font-weight: bold;
  color: #262626;
  text-align: center;
  margin-bottom: 5px;

  @media (max-width: 550px) {
    text-align: left;
    font-size: 36px;
  }
`

const RowBox = styled.div`
  display: flex;
  width: 100%;
  margin: 0px;
  justify-content: center;

  @media (max-width: 767px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`

const ColumnBox = styled.div`
  width: 100%;
  display: flex;
  padding: 20px 0px;

  @media (max-width: 767px) {
    width: 100%;
    padding: 0px;
    margin: 10px 0px;
  }
`

const MattressMessage = styled.p`
  width: 100%;
  margin: 0px;
`

export default injectIntl(HomeCrossSection)
