import React from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"

const VentesSommierSectionWrapper = styled.div`
  width: 100%;
  position: relative;
  background: #e7f0f3;
`

const VentesContainer = styled.div`
  width: 100%;
  max-width: 1300px;
  padding: 15px 20px;
  margin: 0px auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

const DesktopBox = styled.div`
  display: flex;
  max-width: 300px;
  width: 100%;

  @media (max-width: 600px) {
    display: none;
  }
`

const MobileBox = styled.div`
  display: none;
  width: 100%;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    display: flex;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #262626;
  margin-right: 30px;

  @media (max-width: 992px) {
    margin-right: 20px;
  }

  @media (max-width: 600px) {
    margin: 0px;
  }
`

const Title = styled.div`
  font-size: 35px;
  line-height: 1.2;
  font-family: "Museo";
  font-weight: 900;
  text-align: center;
  margin-bottom: 15px;

  span {
    @media (max-width: 600px) {
      display: block;
    }
  }

  @media (max-width: 1600px) {
    margin-bottom: 10px;
    font-size: 30px;
  }

  @media (max-width: 992px) {
    font-size: 24px;
  }
`

const BottomWrapper = styled.div`
  min-width: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 600px) {
    width: 100%;
  }
`

const Text = styled.div`
  font-size: 18px;
  line-height: 1.2;
  font-family: "Gotham Book";
  font-weight: 500;
  text-align: center;

  strong {
    font-weight: bold;
  }

  @media (max-width: 1600px) {
    font-size: 14px;
  }
`

const DesktopDiscountText = styled.div`
  font-size: 14px;
  line-height: 1.2;
  font-family: "Gotham Book";
  font-weight: bold;
  text-align: center;

  span {
    display: block;
    font-size: 24px;
    font-weight: bold;
    font-family: "Gotham";
    // color: ${props => props.theme.saleColor.bgColor};
    color: ${props => props.theme.saleColor.textColor};
    background: ${props => props.theme.saleColor.bgColor};
    padding: 5px 5px 2px;
    max-width: fit-content;
    margin: 0px auto 5px;

    @media (max-width: 1600px) {
      // font-size: 21px;
    }
  }

  @media (max-width: 600px) {
    display: none;
  }
`

const MobileDiscountText = styled.div`
  font-size: 14px;
  line-height: 1.2;
  font-family: "Gotham Book";
  font-weight: bold;
  text-align: center;
  max-width: 130px;
  display: none;

  span {
    display: block;
    font-size: 21px;
    // color: ${props => props.theme.saleColor.bgColor};
    font-weight: bold;
    font-family: "Gotham";
    color: ${props => props.theme.saleColor.textColor};
    background: ${props => props.theme.saleColor.bgColor};
    padding: 5px 5px 2px;
    max-width: fit-content;
    margin: 0px auto 5px;
  }

  @media (max-width: 600px) {
    display: block;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 8px 0px 0px;

  @media (max-width: 600px) {
    width: 100%;
  }
`

const Button = styled(Link)`
  color: #262626;
  font-family: "Museo";
  font-weight: bold;
  font-size: 18px;
  line-height: 1;
  text-align: center;
  border: 1px solid #262626;
  padding: 15px 20px;
  position: relative;
  margin: 0px;

  :hover {
    background: #262626;
    color: #fff;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`

const BannerImage = styled.img`
  width: 100%;
  max-width: 350px;
  margin: 0px;
  object-fit: contain;

  @media (max-width: 600px) {
    max-width: 65%;
  }
`

const VentesSommierSection = ({ intl, data }) => {
  return (
    data && (
      <VentesSommierSectionWrapper>
        <VentesContainer>
          {data.image && (
            <DesktopBox>
              <BannerImage
                className="lazyload"
                data-src={data.image}
                alt={data.alt ? data.alt : ""}
              />
            </DesktopBox>
          )}
          <TextWrapper>
            {data.title && (
              <Title
                dangerouslySetInnerHTML={{
                  __html: data.title,
                }}
              />
            )}
            {data.text && (
              <Text
                dangerouslySetInnerHTML={{
                  __html: data.text,
                }}
              />
            )}
            <MobileBox>
              {data.image && (
                <BannerImage
                  className="lazyload"
                  data-src={data.image}
                  alt={data.alt ? data.alt : ""}
                />
              )}
              {data.discount_text && (
                <MobileDiscountText
                  dangerouslySetInnerHTML={{
                    __html: data.discount_text,
                  }}
                />
              )}
            </MobileBox>
          </TextWrapper>
          <BottomWrapper>
            {data.discount_text && (
              <DesktopDiscountText
                dangerouslySetInnerHTML={{
                  __html: data.discount_text,
                }}
              />
            )}
            {data.button_text && data.button_url && (
              <ButtonWrapper>
                <Button to={data.button_url}>{data.button_text}</Button>
              </ButtonWrapper>
            )}
          </BottomWrapper>
        </VentesContainer>
      </VentesSommierSectionWrapper>
    )
  )
}

export default injectIntl(VentesSommierSection)
