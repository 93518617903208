import React, { useEffect, useState, useRef } from "react"
import styled from "@emotion/styled"
import "flickity/css/flickity.css"
import { isBrowser } from "../../../context/ApolloContext"
import { injectIntl, Link } from "gatsby-plugin-intl"
const $ = isBrowser ? require("jquery") : {}
const Flickity =
  typeof window !== `undefined` ? require("react-flickity-component") : null

const flickityOptions = {
  freeScroll: false,
  pageDots: false,
  wrapAround: true,
  prevNextButtons: true,
}

const ExpertHomeSection = ({ intl, data }) => {
  return (
    <InnovationHomeSectionWrapper>
      <InnovationContentWrapper>
        <InnovationContentBox>
          <Row>
            <LeftBox>
              <DesktopImage
                className="lazyload"
                data-src={data.desktop_image}
                alt={intl.formatMessage({
                  id: "expertise_reconnue_conducteur_SNCF",
                })}
              />
              <MobileImage
                className="lazyload"
                data-src={data.mobile_image}
                alt={intl.formatMessage({
                  id: "expertise_reconnue_conducteur_SNCF",
                })}
              />
            </LeftBox>
            <RightBox>
              <ContentBox>
                <TopSection>
                  {data.title && (
                    <SectionTitle
                      dangerouslySetInnerHTML={{ __html: data.title }}
                    />
                  )}
                  {data.text && (
                    <TextBox>
                      <TopText
                        dangerouslySetInnerHTML={{ __html: data.text }}
                      />
                    </TextBox>
                  )}
                </TopSection>

                <Logo className="lazyload" data-src={data.logo_image} />
                {data.bottom_text && (
                  <TextBox>
                    <BottomText
                      dangerouslySetInnerHTML={{
                        __html: data.bottom_text,
                      }}
                    />
                  </TextBox>
                )}
                {data.button_url && data.button_text && (
                  <ButtonWrapper>
                    <ProductButton to={data.button_url}>
                      {data.button_text}
                    </ProductButton>
                  </ButtonWrapper>
                )}
              </ContentBox>
            </RightBox>
          </Row>
        </InnovationContentBox>
      </InnovationContentWrapper>
    </InnovationHomeSectionWrapper>
  )
}

const InnovationHomeSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px;
  background: #f0f0f0;
  position: relative;
`

const InnovationContentWrapper = styled.div`
  width: 100%;
  margin-top: 50px;

  @media (max-width: 850px) {
    margin-top: 20px;
  }

  @media (max-width: 850px) {
    margin-top: 0px;
  }
`

const InnovationContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  display: flex;
  width: 100%;
  max-height: 380px;
  flex-direction: row-reverse;

  @media (max-width: 550px) {
    flex-direction: column;
    max-height: 100%;
  }
`

const LeftBox = styled.div`
  display: flex;
  width: 100%;
  border-top-left-radius: 100px;
  overflow: hidden;

  @media (max-width: 550px) {
    border-top-left-radius: 0px;
  }
`

const RightBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`

const DesktopImage = styled.img`
  width: 100%;
  object-fit: cover;
  margin: 0px;
  display: flex;

  @media (max-width: 550px) {
    display: none;
  }
`

const MobileImage = styled.img`
  width: 100%;
  object-fit: cover;
  margin: 0px;
  display: none;

  @media (max-width: 550px) {
    display: flex;
  }
`

const TopSection = styled.div`
  width: 100%;

  @media (max-width: 550px) {
    position: absolute;
    top: 0px;
    left: 0px;
    padding: 40px 20px;
  }
`

const TextBox = styled.div`
  color: #262626;
  width: 100%;
  margin: 15px 0px 0px;
`

const TopText = styled.div`
  font-size: 16px;
  line-height: 1.2;
  font-family: "Gotham Book";
  color: #262626;

  @media (max-width: 550px) {
    color: #fff;
  }
`

const BottomText = styled.div`
  font-size: 16px;
  line-height: 1.2;
  font-family: "Gotham Book";
  color: #262626;

  @media (max-width: 550px) {
    text-align: center;
  }
`

const ContentBox = styled.div`
  padding: 20px 10% 20px 20px;
  width: 100%;
  max-width: 540px;

  @media (max-width: 550px) {
    padding: 20px 20px 40px;
  }
`

const SectionTitle = styled.div`
  font-size: 32px;
  line-height: 1.2;
  font-family: "BebasNeueBold";
  font-weight: bold;
  color: #262626;

  @media (max-width: 850px) {
    font-size: 24px;
  }

  @media (max-width: 550px) {
    color: #fff;
  }
`

const Logo = styled.img`
  object-fit: contain;
  margin: 15px 0px 0px;
  max-width: 380px;
  width: 100%;

  @media (max-width: 550px) {
    color: #fff;
    margin: 0px;
    max-width: 100%;
  }
`

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  margin: 15px 0px 0px;
  position: relative;
  z-index: 1;
`

const ProductButton = styled(Link)`
  padding: 12px 20px;
  color: #262626;
  font-family: "Museo";
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  position: relative;
  border: 1px solid ${props => props.theme.colors.border.blue};
  border-radius: 25px 0;

  :hover {
    background: ${props => props.theme.gradient.buttonGradient1};
    border: 0px;
  }

  @media (max-width: 550px) {
    width: 100%;
    text-align: center;
  }
`

export default injectIntl(ExpertHomeSection)
