import React from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"
import ProductHomeSlider from "../ProductHomeSlider"

const ProductHomeSection = ({ intl, data }) => {
  return (
    <ProductHomeSectionWrapper>
      <ProductHomeSectionBox>
        {data.title && (
          <ProductHomeTitle dangerouslySetInnerHTML={{ __html: data.title }} />
        )}
        {data.product_list && <ProductHomeSlider data={data.product_list} />}
      </ProductHomeSectionBox>
    </ProductHomeSectionWrapper>
  )
}

const ProductHomeSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 20px 60px;
  background: #e5e5e5;

  @media (max-width: 550px) {
    padding: 40px 20px 60px;
  }
`

const ProductHomeSectionBox = styled.div`
  width: 100%;
  margin: 0px auto;
  max-width: 1080px;
`

const ProductHomeTitle = styled.div`
  font-size: 32px;
  line-height: 1.2;
  font-family: "BebasNeueBold";
  font-weight: bold;
  color: #262626;
  text-align: center;
  margin-bottom: 10px;

  @media (max-width: 850px) {
    font-size: 24px;
  }

  @media (max-width: 550px) {
    text-align: left;
  }
`

export default injectIntl(ProductHomeSection)
