import React from "react"
import styled from "@emotion/styled"
import "flickity/css/flickity.css"
import { injectIntl } from "gatsby-plugin-intl"

const InstagramHomeSection = ({ intl, data }) => {
  return (
    <InstagramHomeSectionWrapper>
      <InstagramHomeSectionBox>
        {data.title && (
          <MobileInstagramTitle
            dangerouslySetInnerHTML={{ __html: data.title }}
          />
        )}
        <InstagramBox>
          <LeftWrapper>
            {data.title && (
              <DesktopInstagramTitle
                dangerouslySetInnerHTML={{ __html: data.title }}
              />
            )}
            {data.instagram_logo && data.instagram_text && (
              <InstagramTextBox>
                <InstagramImage
                  className="lazyload"
                  data-src={data.instagram_logo}
                />
                <InstagramText
                  dangerouslySetInnerHTML={{ __html: data.instagram_text }}
                />
              </InstagramTextBox>
            )}
            {data.button_url && data.button_text && data.button_url_uk && (
              <ButtonWrapper>
                <ProductButton
                  href={
                    process.env.GATSBY_ACTIVE_ENV === "gbp"
                      ? data.button_url_uk
                      : data.button_url
                  }
                  target="_blank"
                  dangerouslySetInnerHTML={{ __html: data.button_text }}
                />
              </ButtonWrapper>
            )}
          </LeftWrapper>
          <RightWrapper>
            <DesktopImage
              className="lazyload"
              data-src={data.dekstop_image}
              alt={intl.formatMessage({
                id: "publications_instagram_percko",
              })}
            />
            <MobileImage
              className="lazyload"
              data-src={data.mobile_image}
              alt={intl.formatMessage({ id: "publications_instagram_percko" })}
            />
          </RightWrapper>
          <MobileWrapper>
            <InstagramTextBox>
              <InstagramImage
                className="lazyload"
                data-src={data.instagram_logo}
              />
              <InstagramText
                dangerouslySetInnerHTML={{ __html: data.instagram_text }}
              />
            </InstagramTextBox>
            {data.button_url && data.button_text && data.button_url_uk && (
              <ButtonWrapper>
                <ProductButton
                  href={
                    process.env.GATSBY_ACTIVE_ENV === "gbp"
                      ? data.button_url_uk
                      : data.button_url
                  }
                  target="_blank"
                  dangerouslySetInnerHTML={{ __html: data.button_text }}
                />
              </ButtonWrapper>
            )}
          </MobileWrapper>
        </InstagramBox>
      </InstagramHomeSectionBox>
    </InstagramHomeSectionWrapper>
  )
}

const InstagramHomeSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px;
  background: #fff;
  position: relative;
  align-items: center;
  justify-content: center;
`

const InstagramHomeSectionBox = styled.div`
  width: 100%;
  max-width: 1080px;
  padding: 20px 20px 50px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 550px) {
    align-items: flex-start;
    padding: 20px 20px 40px;
  }
`

const InstagramBox = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: 550px) {
    flex-direction: column;
  }
`

const InstagramTextBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 20px;

  @media (max-width: 550px) {
    justify-content: center;
  }
`

const LeftWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 550px) {
    display: none;
  }
`

const RightWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const MobileWrapper = styled.div`
  width: 100%;
  display: none;
  flex-direction: column;

  @media (max-width: 550px) {
    display: flex;
  }
`

const DesktopImage = styled.img`
  width: 100%;
  object-fit: cover;
  margin: 0px;
  display: flex;

  @media (max-width: 550px) {
    display: none;
  }
`

const MobileImage = styled.img`
  width: 100%;
  object-fit: cover;
  margin: 20px 0px 0px;
  display: none;

  @media (max-width: 550px) {
    display: flex;
  }
`

const InstagramImage = styled.img`
  width: 100%;
  object-fit: contain;
  margin: 0px 5px 0px 0px;
  max-width: 25px;
`

const InstagramText = styled.div`
  font-size: 16px;
  line-height: 1.2;
  font-family: "Gotham Book";
  font-weight: bold;
  color: #262626;
`

const DesktopInstagramTitle = styled.div`
  font-size: 32px;
  line-height: 1.2;
  font-family: "BebasNeueBold";
  font-weight: bold;
  color: #262626;

  @media (max-width: 850px) {
    font-size: 24px;
  }

  @media (max-width: 550px) {
    display: none;
  }
`

const MobileInstagramTitle = styled.div`
  font-size: 24px;
  line-height: 1.2;
  font-family: "BebasNeueBold";
  font-weight: bold;
  color: #262626;
  display: none;

  @media (max-width: 550px) {
    display: block;
  }
`

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin: 20px 0px 0px;
  position: relative;

  @media (max-width: 550px) {
    justify-content: center;
  }
`

const ProductButton = styled.a`
  padding: 12px 20px;
  color: #262626;
  font-family: "Museo";
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid ${props => props.theme.colors.border.blue};
  border-radius: 25px 0;

  :hover {
    background: ${props => props.theme.gradient.buttonGradient1};
    border: 0px;
  }

  @media (max-width: 550px) {
    width: 100%;
  }
`

export default injectIntl(InstagramHomeSection)
