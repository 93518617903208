import React, { useRef } from "react"
import styled from "@emotion/styled"
import "flickity/css/flickity.css"
import { isBrowser } from "../../../context/ApolloContext"
import { injectIntl, Link } from "gatsby-plugin-intl"
const $ = isBrowser ? require("jquery") : {}
const Flickity =
  typeof window !== `undefined` ? require("react-flickity-component") : null

const flickityOptions = {
  freeScroll: false,
  pageDots: true,
  wrapAround: true,
  prevNextButtons: true,
}

const InnovationHomeSlider = ({ intl, data }) => {
  var prevNext = useRef()
  return (
    <InnovationHomeSliderWrapper>
      {isBrowser && (
        <Carousel className={"carousel"}>
          <div style={{ width: "100%" }}>
            <Flickity
              flickityRef={c => (prevNext = c)}
              options={flickityOptions}
            >
              {data.list.map((item, index) => {
                return (
                  <Row
                    key={index.toString()}
                    direction={index === 0 ? "row-reverse" : "row"}
                    paddingSide={index === 0 ? "bottom" : "top"}
                  >
                    <LeftBox borderSide={index === 0 ? "left" : "right"}>
                      <MobileImage
                        className="lazyload"
                        data-src={item.mobile_image}
                      />
                    </LeftBox>
                    <RightBox order={index}>
                      <ContentBox order={index}>
                        {item.title && (
                          <SectionTitle
                            dangerouslySetInnerHTML={{ __html: item.title }}
                          />
                        )}
                        {item.text && (
                          <TextBox>
                            {index === 0 && data.medical_logo && (
                              <MedicaleLogo
                                className="lazyload"
                                data-src={data.medical_logo}
                              />
                            )}
                            <Text
                              dangerouslySetInnerHTML={{
                                __html: item.text,
                              }}
                            />
                          </TextBox>
                        )}
                        {item.button_url && item.button_text && (
                          <ButtonWrapper>
                            <ProductButton to={item.button_url}>
                              {item.button_text}
                            </ProductButton>
                          </ButtonWrapper>
                        )}
                      </ContentBox>
                    </RightBox>
                  </Row>
                )
              })}
            </Flickity>
          </div>
        </Carousel>
      )}
    </InnovationHomeSliderWrapper>
  )
}

const InnovationHomeSliderWrapper = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: 550px) {
    padding-top: 20px;
  }
`

const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column-reverse;
  padding: 0px;
  max-height: 100%;
  height: 100%;
  min-height: 580px;
  justify-content: space-between;
`

const LeftBox = styled.div`
  display: flex;
  width: 100%;
  border-top-right-radius: ${({ borderSide }) =>
    borderSide === "right" ? "100px" : "0px"};
  border-bottom-left-radius: 0px;
`

const RightBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: ${({ order }) => (order === 0 ? "flex-end" : "flex-start")};
  align-items: center;
`

const MobileImage = styled.img`
  width: 100%;
  object-fit: cover;
  margin: 0px;
  display: none;

  @media (max-width: 550px) {
    display: flex;
  }
`

const MedicaleLogo = styled.img`
  width: 100%;
  object-fit: contain;
  max-width: 100px;
  margin: 0px 15px 0px 0px;
`

const TextBox = styled.div`
  color: #262626;
  width: 100%;
  margin: 15px 0px;
  display: flex;
  align-items: flex-start;

  @media (max-width: 550px) {
    margin: 15px 0px 0px;
  }
`

const Text = styled.div`
  font-size: 16px;
  line-height: 1.2;
  font-family: "GothamLight";
  color: #262626;
`

const ContentBox = styled.div`
  padding: 20px;
  width: 100%;
  max-width: 540px;
`

const SectionTitle = styled.div`
  font-size: 32px;
  line-height: 1.2;
  font-family: "BebasNeueBold";
  font-weight: bold;
  color: #262626;

  @media (max-width: 850px) {
    font-size: 24px;
  }
`

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  position: absolute;
  bottom: 40px;
  z-index: 1;
`

const ProductButton = styled(Link)`
  background: ${props => props.theme.colors.button.black};
  border-radius: 5px;
  padding: 15px 20px;
  color: #fff;
  font-family: "Museo";
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  border-radius: 25px 0;

  :hover {
    color: #262626;
    background: ${props => props.theme.gradient.buttonGradient1};
    :after {
      background: ${props => props.theme.colors.button.black};
    }
  }

  :after {
    content: "";
    position: absolute;
    top: 5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    background: ${props => props.theme.gradient.buttonGradient1};
    border-radius: 25px 0;
    z-index: -1;

    @media (max-width: 550px) {
      width: calc(100% - 30px);
    }
  }

  @media (max-width: 550px) {
    width: calc(100% - 40px);
    text-align: center;
  }
`

const Carousel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0px auto;
  padding: 0px;

  .flickity-slider {
    display: flex;
    align-items: center;
  }

  .flickity-prev-next-button {
    background-color: transparent;
    color: #111;
    width: 30px;
    height: 30px;
    top: 100%;

    :hover {
      background-color: #111;
      color: #fff;
    }
  }
  .flickity-prev-next-button {
    top: auto;
    bottom: 5px;
    transform: translate(0%, 0%);
  }
  .flickity-prev-next-button.next {
    right: 20px;
  }
  .flickity-prev-next-button.previous {
    left: 20px;
  }
  .flickity-page-dots {
    bottom: 10px;
    max-width: 70px;
    margin: 0px auto;
    left: 0;
    right: 0;
  }
`

export default injectIntl(InnovationHomeSlider)
