import React, { useRef } from "react"
import "flickity/css/flickity.css"
import styled from "@emotion/styled"
const Flickity =
  typeof window !== `undefined` ? require("react-flickity-component") : null

const flickityOptions = {
  freeScroll: false,
  pageDots: true,
  wrapAround: true,
  prevNextButtons: true,
  cellAlign: "left",
}

const TvAdsHomeCarousel = ({ data, sliderLinkText }) => {
  var prevNext = useRef()
  return typeof window !== "undefined" ? (
    <Carousel>
      <div style={{ width: "100%" }}>
        <Flickity
          flickityRef={c => (prevNext = c)}
          options={flickityOptions}
          className={"carousel"}
          static
          reloadOnUpdate
        >
          {data.map((item, index) => {
            return (
              item && (
                <Row key={index.toString()}>
                  <Card>
                    {item.image && (
                      <Image
                        className="lazyload"
                        data-src={`${item.image}?key=small-card`}
                        alt=""
                      />
                    )}
                    <TextBox>
                      {item.text && (
                        <Text
                          dangerouslySetInnerHTML={{
                            __html: item.text,
                          }}
                        />
                      )}
                      {item.link && sliderLinkText && (
                        <CardLink
                          href={item.link}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          {sliderLinkText}
                        </CardLink>
                      )}
                    </TextBox>
                  </Card>
                </Row>
              )
            )
          })}
        </Flickity>
      </div>
    </Carousel>
  ) : null
}

const Row = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  flex-direction: column;
  margin: 0px 15px;
`

const Card = styled.div`
  min-height: 100px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  object-fit: contain;
  background: #fff;
  box-shadow: 0px 3px 10px 1px rgba(206, 206, 206, 0.5);
  padding: 0px 15px;
  box-sizing: border-box;
  border-radius: 5px;
  background-image: url("https://static.percko.com/uploads/889dc717-d9d8-4329-916b-11e97dc19e17.png");
  background-repeat: no-repeat;
  background-position: 95% 90%;
  background-size: 50px;

  @media (max-width: 850px) {
  }

  @media (max-width: 600px) {
    padding: 0px 10px;
  }

  @media (max-width: 480px) {
    min-height: 135px;
  }

  @media (max-width: 384px) {
    min-height: 140px;
  }
`

const Image = styled.img`
  width: 100%;
  object-fit: contain;
  margin: 0;
  max-width: 90px;
  margin-right: 20px;

  @media (max-width: 480px) {
    margin-right: 10px;
  }
`

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`

const Text = styled.div`
  font-weight: normal;
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const CardLink = styled.a`
  text-decoration: underline;
  color: #262626;
  font-size: 14px;
  font-weight: bold;
  margin: 5px 60px 0px 0px;
`

const Carousel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0px 0px 40px;

  .flickity-prev-next-button {
    background-color: transparent;
    color: #111;
    width: 36px;
    height: 36px;
    border: 2px solid #111;
    top: auto;
    bottom: -65px;
    z-index: 2;
    right: 35%;

    @media (max-width: 600px) {
      right: 0;
    }

    :hover {
      background-color: #111;
      color: #fff;
    }
  }

  .flickity-page-dots {
    bottom: -37px;
  }

  .flickity-page-dots .dot {
    margin: 0 3px;
  }

  .flickity-prev-next-button.previous {
    left: 35%;

    @media (max-width: 1024px) {
      left: 20%;
    }

    @media (max-width: 600px) {
      left: 0;
    }
  }

  .flickity-prev-next-button.next {
    right: 35%;

    @media (max-width: 1024px) {
      right: 20%;
    }

    @media (max-width: 600px) {
      right: 0;
    }
  }
`

const PrevNext = styled.div`
  margin: 0 10px;
  width: 20px;
  height: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
  :hover {
    cursor: pointer;
  }
`

const Indicators = styled.img`
  margin: 0;
`

export default TvAdsHomeCarousel
