import React from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { FaArrowRight } from "react-icons/fa"

const VentesProductSectionWrapper = styled.div`
  width: 100%;
  position: relative;
  padding: 20px 0px 0px;
`

const VentesContainer = styled.div`
  width: 100%;
  max-width: 1900px;
  padding: 0px 20px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 767px) {
    padding: 0px 10px;
  }
`

const SectionTitle = styled.h2`
  font-size: 40px;
  line-height: 1.2;
  font-family: "Museo";
  font-weight: 900;
  margin: 0px;
  text-align: center;

  @media (max-width: 850px) {
    font-size: 36px;
  }

  span {
    @media (max-width: 600px) {
      display: block;
    }
  }

  @media (max-width: 600px) {
    font-size: 32px;
  }
`

const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 20px 0px;

  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }

  @media (max-width: 850px) {
    padding: 10px 0px 20px 0px;
  }
`

const ProductBox = styled.div`
  width: 100%;
  margin: 10px 20px 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  color: #fff;

  :last-of-type {
    margin: 10px 0px 10px 0px;

    @media (max-width: 767px) {
      margin: 5px 0px 5px 0px;
    }
  }

  :nth-of-type(3) {
    @media (max-width: 1024px) {
      margin: 10px 0px 10px 0px;
    }
    @media (max-width: 767px) {
      margin: 5px 0px 5px 0px;
    }

    @media (max-width: 600px) {
      margin: 5px 10px 5px 0px;
    }
  }

  :nth-of-type(even) {
    @media (max-width: 600px) {
      margin: 5px 0px 5px 0px;
    }
  }

  @media (max-width: 1024px) {
    flex-basis: calc(33% - 12px);
  }

  @media (max-width: 767px) {
    margin: 5px 10px 5px 0px;
    flex-basis: calc(33% - 5px);
  }

  @media (max-width: 600px) {
    margin: 5px 10px 5px 0px;
    flex-basis: calc(50% - 5px);
  }
`

const AbsoluteButton = styled(Link)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  cursor: pointer;
  text-indent: -9999px;
  z-index: 1;
`

const ButtonTitle = styled.span`
  display: none;
`

const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0px;
  padding: 20px;

  @media (max-width: 600px) {
    padding: 20px 10px;
    flex-direction: row;
  }
`

const Title = styled.div`
  font-size: 26px;
  line-height: 1.2;
  font-family: "Museo";
  font-weight: 900;
  text-align: center;

  @media (max-width: 1600px) {
    font-size: 24px;
  }

  @media (max-width: 1200px) {
    font-size: 21px;
  }

  @media (max-width: 600px) {
    font-size: 16px;
    text-align: left;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }

  @media (max-width: 380px) {
    font-size: 12px;
  }
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  margin: 0px;
  top: 0px;
  object-fit: cover;
    aspect-ratio: 2 / 3;
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 1;
  position: relative;
  margin: 5px 0px 0px;

  @media (max-width: 600px) {
    margin: 0px 0px 0px 10px;
  }

  @media (max-width: 480px) {
    margin: 0px 0px 0px 5px;
  }
`

const Button = styled(Link)`
  padding: 7px 10px;
  color: #fff;
  font-family: "Museo";
  font-weight: bold;
  font-size: 18px;
  line-height: 1;
  text-align: center;
  margin: 0px;
  border: 1px solid #fff;

  :hover {
    color: #262626;
    background: #fff;
  }

  @media (max-width: 1200px) {
    font-size: 16px;
  }

  @media (max-width: 600px) {
    display: none;
  }
`

const MobileButton = styled(Link)`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 2px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;

  @media (max-width: 600px) {
    display: flex;
  }

  @media (max-width: 480px) {
    width: 30px;
    height: 30px;
  }
`

const TopTagWrapper = styled.div`
  position: absolute;
  left: 0px;
  top: 20px;
  background: #fff;
  color: #262626;
  font-size: 20px;
  line-height: 1;
  font-family: "BebasNeueBold";
  font-weight: bold;
  padding: 5px 8px;
`

const VentesProductSection = ({ intl, data }) => {
  return (
    data && (
      <VentesProductSectionWrapper>
        <VentesContainer>
          {data.section_title && (
            <SectionTitle
              dangerouslySetInnerHTML={{
                __html: data.section_title,
              }}
            />
          )}
          {data.list && (
            <RowWrapper>
              {data.list.map((item, index) => {
                return (
                  item && (
                    <ProductBox key={index.toString()}>
                      {item.button_text && item.button_url && (
                        <AbsoluteButton to={item.button_url}>
                          <ButtonTitle>{item.button_text}</ButtonTitle>
                        </AbsoluteButton>
                      )}
                      {item.image && (
                        <Image
                          src={item.image}
                          alt={item.alt ? item.alt : ""}
                        />
                      )}
                      {item.top_text && (
                        <TopTagWrapper
                          dangerouslySetInnerHTML={{
                            __html: item.top_text,
                          }}
                        />
                      )}
                      <TextWrapper>
                        {item.title && (
                          <Title
                            dangerouslySetInnerHTML={{
                              __html: item.title,
                            }}
                          />
                        )}
                        {item.button_text && item.button_url && (
                          <ButtonWrapper>
                            <Button to={item.button_url}>
                              {item.button_text}
                            </Button>
                            <MobileButton to={item.button_url}>
                              <FaArrowRight
                                style={{ cursor: "pointer" }}
                                color="#fff"
                                size={18}
                              />
                            </MobileButton>
                          </ButtonWrapper>
                        )}
                      </TextWrapper>
                    </ProductBox>
                  )
                )
              })}
            </RowWrapper>
          )}
        </VentesContainer>
      </VentesProductSectionWrapper>
    )
  )
}

export default injectIntl(VentesProductSection)
