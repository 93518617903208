import React from "react"
import styled from "@emotion/styled"
import { isBrowser } from "../../../context/ApolloContext"
import Slider from "react-slick"
import { FaAngleLeft, FaAngleRight, FaArrowRight } from "react-icons/fa"
import { injectIntl, Link } from "gatsby-plugin-intl"

const RightArrows = props => {
  const { className, style, onClick } = props
  return (
    <button
      className="slider-prev-next-button next"
      type="button"
      aria-label="Next"
      onClick={onClick}
    >
      <FaAngleRight size={30} />
    </button>
  )
}

const PrevArrows = props => {
  const { className, style, onClick } = props
  return (
    <button
      className="slider-prev-next-button prev"
      type="button"
      aria-label="Previous"
      onClick={onClick}
    >
      <FaAngleLeft size={30} />
    </button>
  )
}

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  variableWidth: true,
  slidesToScroll: 1,
  nextArrow: <RightArrows />,
  prevArrow: <PrevArrows />,
}

const ProductHomeSlider = ({ intl, data }) => {
  return (
    <ProductBoxWrapper>
      {isBrowser && (
        <Slider {...settings}>
          {data.map((item, index) => {
            return (
              <ProductBox key={index.toString()} to={item.button_url}>
                <ProductImage
                  className="lazyload"
                  data-src={item.image}
                  alt={intl.formatMessage({
                    id:
                      index === 0
                        ? "lyne_up_percko_sous_vetement_femme_bureau"
                        : index === 1
                        ? "lyne_fit_percko_tshirt_sport_couple_course"
                        : index === 2
                        ? "lyne_home_percko_femme_cuisine"
                        : "loop_percko_accessoire_automassage",
                  })}
                />
                {item.top_text && (
                  <TopTextBox>
                    <TopText
                      dangerouslySetInnerHTML={{
                        __html: item.top_text,
                      }}
                    />
                  </TopTextBox>
                )}
                <ContentBox>
                  {item.title && (
                    <ProductTitle
                      dangerouslySetInnerHTML={{ __html: item.title }}
                    />
                  )}
                  {item.logo_image && (
                    <ProductLogo
                      className="lazyload"
                      data-src={item.logo_image}
                      logo={index === 2 ? "lynehome" : "otherlogo"}
                    />
                  )}
                  {item.button_url && (
                    <ProductButton to={item.button_url}>
                      <FaArrowRight
                        style={{ cursor: "pointer" }}
                        color="#fff"
                        size={18}
                      />
                    </ProductButton>
                  )}
                </ContentBox>
              </ProductBox>
            )
          })}
        </Slider>
      )}
    </ProductBoxWrapper>
  )
}

const ProductBoxWrapper = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: 1024px) {
    width: calc(100% + 20px);
  }

  .slick-slider {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
  }

  .slick-dots li {
    width: 10px;
    height: 10px;
  }

  .slick-dots li button {
    width: 10px;
    height: 10px;
    padding: 0px;
  }

  .slick-dots li button:before {
    width: 10px;
    height: 10px;
    font-size: 11px;
    line-height: 10px;
  }

  .slider-prev-next-button {
    border: 2px solid #262626;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    position: absolute;
    bottom: -32px;
    z-index: 1;
    cursor: pointer;
  }

  .slider-prev-next-button.prev {
    left: 35%;

    @media (max-width: 767px) {
      left: 25%;
    }

    @media (max-width: 600px) {
      left: 0px;
    }
  }

  .slider-prev-next-button.next {
    right: 35%;

    @media (max-width: 767px) {
      right: 25%;
    }

    @media (max-width: 600px) {
      right: 20px;
    }
  }
`

const ProductBox = styled(Link)`
  display: flex;
  width: 100%;
  max-width: 230px;
  min-width: 230px;
  margin: 20px 25px 20px 15px;
  box-shadow: 0 3px 20px rgb(94 178 166 / 40%);
  border-radius: 30px 0px 45px 0px;
  position: relative;
  cursor: pointer;
  align-items: center;

  @media (max-width: 420px) {
    margin: 10px 20px 10px 10px;
  }
`

const ProductImage = styled.img`
  width: 100%;
  object-fit: contain;
  margin: 0px;
`

const TopTextBox = styled.div`
  background: #262626;
  padding: 10px 20px;
  border-radius: 15px 0px 15px 0px;
  text-align: center;
  position: absolute;
  top: -5px;
  left: -5px;
  width: auto;
`

const TopText = styled.div`
  font-size: 14px;
  line-height: 1.2;
  font-family: "Museo";
  font-weight: 700;
  color: #fff;
`

const ContentBox = styled.div`
  padding: 10px 15px;
  position: absolute;
  bottom: 7px;
  width: 100%;
`

const ProductTitle = styled.div`
  font-size: 13px;
  line-height: 1.2;
  font-family: "Museo";
  font-weight: 700;
  color: #262626;
`

const ProductLogo = styled.img`
  object-fit: contain;
  margin: 0px;
  max-width: ${({ logo }) => (logo === "lynehome" ? "150px" : "110px")};
  max-height: 35px;
  min-height: 35px;
`

const ProductButton = styled.div`
  background: #262626;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 10px;
  right: 15px;
`

export default injectIntl(ProductHomeSlider)
